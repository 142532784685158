<template>
  <div class="page">
    <div class="top flexcenter">
      <div class="left flexcenter">
        <i class="iconfont icondianpu"></i>
        <span>途够心选</span>
      </div>
      <!-- <div class="right">编辑</div> -->
    </div>
  
    <div v-if="goods.length">
      <div class="cart-list">
        <product1
            :is-show-chose="true"
            v-for="(item, idx) in goods"
            :key="item.goods_id"
            :goods="item"
            :number.sync="item.goods_number"
            :checkedSku="checked"
            @refesh-page="refeshPage"
            @checked-goods="checkedGoods"
        ></product1>
      </div>
    
      <div class="bottom flexcenter">
        <div class="yixuan flexcenter" @click="onCheckedAll">
		  <span v-if="checkedAll" class="iconfont iconxuanzhong"></span>
		  <span v-else class="iconfont iconweixuanzhong"></span>
          <span>全选</span>
        </div>
        <div class="cashier flexcenter">
          <div>
            <div class="flexcenter">
              <div class="heji">合计: </div>
              <div class="price">￥{{checkedGoodsAmount}}</div>
            </div>
            <div class="hyf">含运费</div>
          </div>
          <div class="buy" @click="goCheckout">结算</div>
        </div>
      </div>
    </div>
  
    <div v-else>
      <div class="empty-box">
        <img class="em-img" mode="widthFix" src="~images/cart-empty.png">
        <div class="desc1">购物车还是空的</div>
        <div class="desc2">赶紧买点宝贝慰劳下自己吧</div>
        <div class="qgg">去逛逛</div>
      </div>
    </div>
	
	<van-tabbar route active-color="#FF8A00">
	  <van-tabbar-item replace to="/" icon="home-o">首页</van-tabbar-item>
	  <van-tabbar-item replace to="/products" icon="search">商品</van-tabbar-item>
	  <van-tabbar-item replace to="/cart" icon="cart-o">购物车</van-tabbar-item>
	  <van-tabbar-item replace to="/myinfo" icon="manager-o">我的</van-tabbar-item>
	</van-tabbar>
  </div>
</template>

<script>
  import mixin from 'utils/mixin'
  import {mapGetters} from 'vuex'
  import product1 from 'components/product1'
  
  export default {
    name: 'cart',
	mixins: [mixin],
    data() {
      return {
        goods:[], //购物车商品信息
        checkedGoodsAmount:0,//合计
        checked:[],
        checkedAll:false, //全选
      }
    },
    computed: {
      ...mapGetters([
        'user',
      ])
    },
    components: {
      product1,
    },
    created() {
      this.refeshPage()
    },
    methods: {
      //刷新购物车页面数据
      refeshPage() {
        let isFetch = this.user || 1
        if(isFetch) {
          let that = this;
          this.post(
            this.API.cartList,{group:''},
          ).then(response => {
            if(response.data) {
              this.goods = response.data;
			  
			  let checked = this.checked;
			  let goods = this.goods;
			  let _checkedGoodsAmount = 0;
			  checked.forEach(function(sku_id,v){
			    goods.forEach(function(goods,k){
			      if(sku_id==goods.sku_id)
			      {
			        _checkedGoodsAmount = _checkedGoodsAmount + goods.goods_number * goods.order_price;
			      }
			    })
			  })
			  
			  this.checkedGoodsAmount = _checkedGoodsAmount;
            }
          })
        }
      },
      //选择商品
      checkedGoods:function(e){
        if(typeof e != 'undefined')
        {
          let sku_id = e.sku_id;
          let status = e.status;
          let checked = this.checked;
          if(status){
            if(!this.InArray(sku_id,checked))
            {
              checked.push(sku_id);
              this.checked=checked;
            }
          }else{
            if(this.InArray(sku_id,checked))
            {
              let idx = checked.indexOf(sku_id);
              if (idx > -1) {
                checked.splice(idx, 1);
              }
            }
          }
        }

        let checked = this.checked;
        let goods = this.goods;
        let _checkedGoodsAmount = 0;
        checked.forEach(function(sku_id,v){
          goods.forEach(function(goods,k){
            if(sku_id==goods.sku_id)
            {
              _checkedGoodsAmount = _checkedGoodsAmount + goods.goods_number * goods.order_price;
            }
          })
        })

        this.checkedGoodsAmount = _checkedGoodsAmount;

        if(this.checked.length==this.goods.length)
        {
          this.checkedAll = true;
        }else{
          this.checkedAll = false;
        }
      },
      /**
       * 全选
       * */
      onCheckedAll:function(){

        let that = this;
        let checked = [];
        let goods = this.goods;
        let checkedGoodsAmount = 0;
        let checkedAll = this.checkedAll;

        if(!checkedAll)
        {
          goods.forEach(function(goods,k){
            checked.push(goods.sku_id);
            checkedGoodsAmount = checkedGoodsAmount + goods.goods_number * goods.order_price;
          })

          this.checked = checked,
          this.checkedAll =!checkedAll,
          this.checkedGoodsAmount = checkedGoodsAmount;
        }
        else
        {
            this.checked = checked;
            this.checkedAll = !checkedAll;
            this.checkedGoodsAmount = 0;
        }

      },
      /**
       * 结算
       */
      goCheckout:function() {

        if(this.checked.length==0)
        {
          this.$toast("请选择要结算的商品");
          return false;
        }

        let checked = JSON.stringify(this.checked);
        //console.log(typeof checked);
		this.$router.push({
		  name: 'spjs',
		  query: {
		    skuids: checked,
		  }
		})
      
      },
    }
  };
</script>

<style lang="scss" scoped>
  .page {
    height: 100%;
    background-color: #F5F5F5;
  }
  .top {
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 30px;
    height: 86px;
    background-color: #FFFFFF;
    font-size: 24px;
  }
  .top .left {
    color: #191919;
  }
  .top .left .iconfont {
    margin-right: 12px;
    font-size: 40px;
  }
  .top .right {
    color: #9B9B9B;
  }

  .cart-list {
    background-color: #FFFFFF;
	margin-bottom: 240px;
  }

  .bottom {
    justify-content: space-between;
    position: fixed;
    z-index: 1000;
    left: 0;
    right: 0;
    bottom: 96px;
	bottom: calc(96px + constant(safe-area-inset-bottom));//兼容 IOS<11.2
	bottom: calc(96px + env(safe-area-inset-bottom));//兼容 IOS>11.2
    box-sizing: border-box;
    padding: 0 30px;
	background: #FFFFFF;
	border-bottom: 0.01333rem solid #eee;
    height: 110px;
  }
  .bottom .yixuan {
    width: 210px;
    height: 52px;
    line-height: 52px;
    border-radius: 32px;
    color: #191919;
    font-size: 24px;
  }
  .bottom .yixuan .iconfont {
    margin-right: 20px;
  }
  .bottom .heji {
    font-size: 24px;
    color: #191919;
  }
  .bottom .price {
    margin-right: 40px;
    font-size: 30px;
    color: #F84F41;
  }
  .bottom .hyf {
    margin-right: 40px;
    font-size: 24px;
    color: #777777;
    text-align: right;
  }
  .bottom .buy {
    width: 192px;
    height: 72px;
    line-height: 72px;
    border-radius: 36px;
    background-color: #FCC601;
    text-align: center;
    font-size: 30px;
    color: #FFFFFF;
  }

  .empty-box {
    text-align: center;
  }
  .empty-box .em-img {
    display: block;
    margin: 64px auto;
    width: 318px;
  }
  .empty-box .desc1 {
    font-size: 32px;
    color: #777777;
  }
  .empty-box .desc2 {
    margin: 16px 0 20px;
    font-size: 28px;
    color: #C7C7C7;
  }
  .empty-box .qgg {
    margin: 20px auto;
    width: 192px;
    height: 72px;
    line-height: 72px;
    border: 1px solid #FCC601;
    border-radius: 36px;
    font-size: 32px;
    font-weight: bold;
    color: #FCC601;
  }
  .empty-more {
    padding: 20px;
  }
  .empty-more .title {
    margin-bottom: 20px;
    text-align: center;
    font-size: 28px;
    color: #191919;
  }
  .empty-more .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

</style>
